import { Route } from 'react-router-dom';

import Auth from 'src/Auth/Auth';
import { isGuest } from 'src/Auth/common';
import Loading from 'src/components/Loading';

interface GuestRouteProps {
  component: React.ComponentType<any>;
  componentProps?: any;
  [key: string]: any;
}

const GuestRoute = ({
  component: Component,
  componentProps,
  ...restProps
}: GuestRouteProps) => {
  return (
    <Route
      {...restProps}
      render={props => {
        const isAuthenticated = Auth.isAuthenticated();

        if (!isAuthenticated) {
          Auth.login();
        }

        if (isAuthenticated && isGuest()) {
          return <Component {...componentProps} {...props} />;
        }

        return <Loading />;
      }}
    />
  );
};

export default GuestRoute;
