import { gql } from 'src/generated/gql';

export const createProductDocumentVersion = gql(`
  mutation createProductDocumentVersion($productId: ID!) {
    createProductDocumentVersion(productId: $productId) {
      id
      productId
      versionTimestamp
      status
      document {
        id
        name
        architectureId
        description
        available
        requiresContent
        isMlp
        isCoMarketing
      }
    }
  }
`);

export const updateProductDocumentVersion = gql(`
  mutation updateProductDocumentVersion(
    $id: ID!
    $document: ProductDocumentInput!
  ) {
    updateProductDocumentVersion(id: $id, document: $document) {
      id
      productId
      versionTimestamp
      status
      document {
        id
        name
        architectureId
        description
        available
        requiresContent
        isMlp
        isCoMarketing
      }
    }
  }
`);

export const submitProductDocumentVersion = gql(`
  mutation submitProductDocumentVersion($id: ID!) {
    submitProductDocumentVersion(id: $id) {
      id
      productId
      versionTimestamp
      status
      document {
        id
        name
        architectureId
        description
        available
        requiresContent
        isMlp
        isCoMarketing
      }
    }
  }
`);
