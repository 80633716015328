import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';

import { Button, Box, Typography } from '@mui/material';
import MailLockIcon from '@mui/icons-material/MailLock';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { validateEmail } from 'src/common/validations';
import DynamicForm from 'src/components/ReduxForm/DynamicForm/DynamicForm';
import { useAppSettings } from 'src/AppSettings';
import { sendGuestMagicLink } from 'src/components/AuthGuestModal/mutations';
import Loading from 'src/components/Loading';

const emailInputName = 'email';
const inputs = [
  {
    name: emailInputName,
    displayName: t('guestAuth.guestMagicLinkForm.email', 'Email'),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateEmail()],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

interface GuestMagicLinkFormData {
  email: string;
}

const GuestMagicLinkForm = () => {
  const appSettings = useAppSettings();
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm<GuestMagicLinkFormData>({
    mode: 'all',
    shouldUnregister: false,
    resetOptions: {
      keepDefaultValues: true,
      keepValues: true
    }
  });

  const email = formMethods.watch(emailInputName);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [sendMagicLink, { loading }] = useMutation(sendGuestMagicLink);

  const onSubmit = async (data: GuestMagicLinkFormData) => {
    const { organizationFqdn } = appSettings;

    if (!organizationFqdn) {
      // this should always be set, but just in case & to appease typescript
      return;
    }

    try {
      setHasSubmitted(true);

      await sendMagicLink({
        variables: {
          email: data.email,
          fqdn: organizationFqdn
        }
      });

      enqueueSnackbar(t('guestAuth.guestMagicLinkForm.successMessage'), {
        variant: 'success'
      });
    } catch (error) {
      setHasSubmitted(false);
      enqueueSnackbar(t('guestAuth.guestMagicLinkForm.errorMessage'), {
        variant: 'error'
      });
    }
  };

  const resendMagicLink = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onSubmit({
      email
    });
  };

  if (hasSubmitted) {
    return (
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', mb: loading ? 0 : 1 }}
        >
          {loading ? (
            <Loading inline size={20} />
          ) : (
            <MarkEmailReadIcon
              sx={{ mr: 1, color: theme => theme.palette.grey[600] }}
            />
          )}

          <Typography
            variant="body1"
            sx={{ my: 1, fontWeight: 'bold', ml: loading ? 1 : 0 }}
          >
            {loading
              ? t('guestAuth.guestMagicLinkForm.loading')
              : t('guestAuth.guestMagicLinkForm.magicLinkSuccess')}
          </Typography>
        </Box>
        {!loading && (
          <>
            <Typography variant="body2" sx={{ py: 1 }}>
              <Trans
                i18nKey="guestAuth.guestMagicLinkForm.notReceived"
                components={[
                  <Box
                    component="a"
                    onClick={() => {
                      resendMagicLink();
                    }}
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: theme => theme.palette.primary.main
                    }}
                  />
                ]}
              />
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                resendMagicLink();
              }}
            >
              {t('guestAuth.guestMagicLinkForm.resend')}
            </Button>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <MailLockIcon sx={{ mr: 1, color: theme => theme.palette.grey[600] }} />

        <Typography variant="body1" sx={{ my: 1, fontWeight: 'bold' }}>
          {t('guestAuth.guestMagicLinkForm.magicLinkText')}
        </Typography>
      </Box>
      <FormProvider {...formMethods}>
        <Box
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={formMethods.handleSubmit(onSubmit)}
        >
          <DynamicForm isHookForm inputs={inputs} />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {t('guestAuth.guestMagicLinkForm.sendLoginLink')}
          </Button>
        </Box>
      </FormProvider>
    </>
  );
};

export default GuestMagicLinkForm;
