import { useState, useMemo, useEffect } from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import i18n from 'src/i18n';

import GlobalStyles from 'src/components/theme/GlobalStyles';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';

import { green, orange } from '@mui/material/colors';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider
} from '@mui/material/styles';
import {
  enUS,
  jaJP,
  caES,
  zhCN,
  csCZ,
  nlNL,
  frFR,
  srRS,
  arSA,
  deDE,
  elGR,
  heIL,
  itIT,
  plPL,
  ptPT,
  ptBR,
  roRO,
  esES,
  thTH,
  trTR,
  viVN
} from '@mui/material/locale';

import { allowListPaths } from 'src/routes/paths';
import useGoogleTagManager from 'src/common/Tracker/useGoogleTagManager';
import AdmiralSnackBarProvider from 'src/components/AdmiralSnackBar/AdmiralSnackbarProvider';
import ErrorHandler from 'src/components/ErrorHandler';
import {
  ThemeSwitcherProvider,
  THEME_NAMES
} from 'src/context/ThemeSwitcherContext';
import { withAppSettings } from 'src/AppSettings';
import AuthAndApolloProvider from 'src/AuthAndApolloProvider';
import App from 'src/App';
import { LOCALE_CODE } from 'src/pages/Account/LocalizationPreferences/helpers';
import amplitude from 'src/instrumentation/amplitude';

import MuiLocalizationProvider from './MuiLocalizationProvider';

const evToMuiLocale = {
  [LOCALE_CODE.english]: enUS,
  [LOCALE_CODE.albanian]: enUS, // missing albanian
  [LOCALE_CODE.arabic]: arSA,
  [LOCALE_CODE.catalan]: caES,
  [LOCALE_CODE.chineseSimplified]: zhCN,
  [LOCALE_CODE.czech]: csCZ,
  [LOCALE_CODE.dutch]: nlNL,
  [LOCALE_CODE.french]: frFR,
  [LOCALE_CODE.frenchCanadian]: frFR, // missing french canadian
  [LOCALE_CODE.german]: deDE,
  [LOCALE_CODE.greek]: elGR,
  [LOCALE_CODE.hebrew]: heIL,
  [LOCALE_CODE.italian]: itIT,
  [LOCALE_CODE.japanese]: jaJP,
  [LOCALE_CODE.malay]: enUS, // missing malay (in our current version (15.12.0) of MUI)
  [LOCALE_CODE.mongolianCyrillic]: enUS, // missing mongolian
  [LOCALE_CODE.polish]: plPL,
  [LOCALE_CODE.portuguese]: ptPT,
  [LOCALE_CODE.portugueseBrazil]: ptBR,
  [LOCALE_CODE.romanian]: roRO,
  [LOCALE_CODE.serbian]: srRS,
  [LOCALE_CODE.slovenian]: enUS, // missing slovenian
  [LOCALE_CODE.spanish]: esES,
  [LOCALE_CODE.spanishLatin]: esES, // missing latin american spanish
  [LOCALE_CODE.thai]: thTH,
  [LOCALE_CODE.turkish]: trTR,
  [LOCALE_CODE.vietnamese]: viVN
};

const generateTheme = appSettings => {
  return {
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true // No more ripple, on the whole application 💣!
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true // No more ripple, on the whole application 💣!
        }
      },
      MuiButtonGroup: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true // Enable arrow by default
        },
        styleOverrides: {
          tooltip: {
            fontSize: '12px'
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '40px'
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      }
    },
    palette: {
      primary: {
        main: null,
        contrastText: null,
        ...appSettings?.app?.palette?.primary
      },
      secondary: {
        main: null,
        contrastText: null,
        ...appSettings?.app?.palette?.secondary
      },
      background: {
        overlay: 'rgba(0, 0, 0, 0.5)'
      },
      facebook: {
        main: '#0044ff'
      },
      google: {
        blue: '#3888ef',
        green: '#2ca759',
        red: '#ed423c',
        yellow: '#fdb936'
      },
      blueprintIcons: {
        background: '#ffffff',
        multiImage: '#4a90e2',
        profileIcon: '#e9e9ed',
        singleImage: '#50e3c2',
        text: '#e9e9ed',
        video: '#7889b9',
        channel: {
          audienceNetwork: '#757575', // facebook = #392d85
          facebook: '#757575',
          instagram: '#757575',
          messenger: '#757575',
          google: {
            blue: '#757575', // googles = #3888ef
            green: '#757575', // googles = #2ca759
            red: '#757575', // googles = #ed423c
            yellow: '#757575' // googles = #fdb936
          }
        }
      },
      success: green,
      warning: orange,
      inputBorderColor: appSettings?.colors?.inputBorder
    },
    typography: {
      fontFamily:
        appSettings?.fonts?.primary?.fontFamily ||
        'Roboto, Helvetica, Arial, sans-serif'
    },
    app: {
      ...appSettings?.app
    },
    // global assets like images, etc...
    evAssets: {
      ...appSettings?.evAssets
    },
    // global sizes
    evSizes: {
      ...appSettings?.evSizes
    }
  };
};

export const BACKGROUND_GREY = {
  light: '#FDFDFD',
  facebook: '#F1F2F5'
};

const AppThemeWrapper = ({ appSettings }) => {
  const [themeName, setThemeName] = useState(THEME_NAMES.default);

  const currentLanguage = i18n.language;
  const [locale, setLocale] = useState(currentLanguage);

  useEffect(() => {
    if (currentLanguage !== locale) {
      setLocale(currentLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const location = useLocation();

  const queryParams = parse(location.search, {
    ignoreQueryPrefix: true
  });

  // loads on mount avoiding the state where undefined using useEffect
  // we never want an undefined promise
  const [amplitudeInitPromise] = useState(() =>
    import.meta.env.EVOCALIZE_ENV !== 'test'
      ? amplitude.preInit({
          utmSource: queryParams?.utm_source,
          utmMedium: queryParams?.utm_medium,
          utmCampaign: queryParams?.utm_campaign,
          utmTerm: queryParams?.utm_term,
          utmContent: queryParams?.utm_content,
          organizationId: appSettings?.organizationId
        })
      : Promise.resolve()
  );

  const theme = useMemo(() => {
    const defaultTheme = generateTheme(appSettings);
    const programAndAutomationCheckoutForm = {
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        background: {
          ...defaultTheme.palette.background,
          default: BACKGROUND_GREY.light
        }
      }
    };
    const facebookPageLinkingTheme = {
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        background: {
          ...defaultTheme.palette.background,
          default: BACKGROUND_GREY.facebook
        }
      }
    };

    const themes = {
      default: defaultTheme,
      automationPage: programAndAutomationCheckoutForm,
      programCreatePage: {
        ...programAndAutomationCheckoutForm,
        evSizes: {
          ...programAndAutomationCheckoutForm.evSizes
        }
      },
      facebookPageLinking: facebookPageLinkingTheme
    };

    return createTheme(themes[themeName], evToMuiLocale[locale]);
  }, [appSettings, themeName, locale]);

  useGoogleTagManager(appSettings);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeSwitcherProvider value={setThemeName}>
        <ThemeProvider theme={theme}>
          <MuiLocalizationProvider>
            <GlobalStyles appSettings={appSettings} />
            <AdmiralSnackBarProvider theme={theme}>
              <ErrorHandler>
                <AuthAndApolloProvider allowList={allowListPaths}>
                  <App amplitudeInitPromise={amplitudeInitPromise} />
                </AuthAndApolloProvider>
              </ErrorHandler>
            </AdmiralSnackBarProvider>
          </MuiLocalizationProvider>
        </ThemeProvider>
      </ThemeSwitcherProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = state => {
  return {
    // Note: We are doing this with location so that it updates components on a route change
    //       seems to be an issues with connected-react-router.
    // TODO: Update connected-react-router or react-redux to see if it fixes the issue.
    location: state?.router?.location?.pathname
  };
};

export default flow(connect(mapStateToProps), withAppSettings)(AppThemeWrapper);
