import { gql } from 'src/generated/gql';

// export const loadDashboard = gql(`
//     query ($showInactive: Boolean) {
//         me {
//             id
//         }
//         us {
//             id
//             name
//             role
//             settings
//             architectures(showInactive: $showInactive) {
//                 id
//                 name
//                 catalog {
//                     id
//                     contentSetId
//                 }
//             }
//             activeQuickStarts(showInactive: $showInactive) {
//                 id
//                 name
//                 description
//                 isActive
//                 priority
//                 productId
//                 product {
//                     id
//                     architectureId
//                     description
//                     name
//                     notes
//                     catalogContentType
//                     blueprint {
//                         objective
//                         publishers {
//                             id
//                             prototype {
//                                 id
//                                 code
//                                 unit {
//                                     id
//                                     channelCode
//                                     code
//                                 }
//                             }
//                             publisherFacets {
//                                 id
//                                 facet {
//                                     id
//                                     code
//                                 }
//                                 parameterValues
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;

export const getQuickStarts = gql(`
  query getQuickStarts($showInactive: Boolean, $filter: ActiveQuickStartsFilter) {
    activeQuickStarts(showInactive: $showInactive, filter: $filter) {
      id
      name
      description
      isActive
      isCustom
      customImageUrl
      iconImageUrl
      priority
      productId
      product {
        id
        architectureId
        description
        name
        notes
        catalogContentType
        channels
        isMlp
        isCoMarketing
        blueprint {
          id
          objective
        }
      }
      automationQuickStarts {
        id
        productQuickStartId
        name
        icon
        priority
        catalogFilter
      }
    }
  }
`);

export const getQuickstartsForObjectiveFilter = gql(`
  query getQuickStartsForObjectiveFilter($showInactive: Boolean, $filter: ActiveQuickStartsFilter) {
    activeQuickStarts(showInactive: $showInactive, filter: $filter) {
      product {
        blueprint {
          objective
          objectives {
            slug
            name
          }
        }
      }
    }
  }
`);

export const ordersV2 = gql(`
  query ordersV2ForDashboard {
    ordersV2(first: 500) {
      edges {
        node {
          programId
          channels {
            channels
          }
          products(filter: { bypassPermissions: true }) {
            kpis {
              slug
              channelCode
              friendlyName
            }
          }
        }
      }
    }
  }
`);

export const getContentDrivenPreviews = gql(`
  query getContentDrivenPreviews {
    getContentDrivenPreviews {
      previewProgramId
      product {
        id
        architectureId
        name
        description
        isAvailable
        blueprintId
        inputSections {
          inputFields {
            blueprintVariableId
            displayMethodId
            displayName
            displayParameters
            displaySortOrder
            isEditable
            isHidden
            productId
            sectionId
          }
        }
        notes
        catalogContentType
        requiresContent
        channels
      }
      contentItemIds
    }
  }
`);

export const getArchitectureAndCatalogByArchitectureId = gql(`
  query getArchitectureAndCatalogByArchitectureIdForDashboard(
    $architectureId: ID!
    $externalIds: [String]!
  ) {
    architecture(architectureId: $architectureId) {
      catalogs {
        displayNameTemplate
        content(filter: { id: { in: $externalIds } }) {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
        }
      }
    }
  }
`);
