import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { t } from 'i18next';

import { Box, Divider, Drawer, Toolbar, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme, Theme } from '@mui/material/styles';

// TODO: prob should move this to a constants file in ad preview vs in program
import { DRAWER_FULL_SCREEN_BREAKPOINT } from 'src/pages/Program/ProgramPreviewDrawer/constants';
import DrawerToggleButton from 'src/pages/Program/ProgramPreviewDrawer/DrawerToggleButton';

export const DrawerDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0)
}));

interface PreviewDrawerProps {
  children: React.ReactNode;
  previewDrawerOpen: boolean;
  togglePreviewDrawer: () => void;
  toggleButtonText?: string;
}

export const getPreviewDrawerStyles = ({
  theme,
  previewDrawerOpen,
  drawerPosition,
  drawerFullScreenBreakpoint
}: {
  theme: Theme;
  previewDrawerOpen: boolean;
  drawerPosition: string;
  drawerFullScreenBreakpoint: number;
}) => {
  return {
    '& .MuiDrawer-paper': {
      paddingTop: 0,
      [theme.breakpoints.down(drawerFullScreenBreakpoint)]: {
        paddingTop: theme.evSizes.toolBarHeight
      },

      ...(previewDrawerOpen && {
        transition: theme.transitions.create('width', {
          duration: 0
        }),
        maxWidth: theme.evSizes.previewDrawerWidth,
        width: theme.evSizes.previewDrawerWidth,
        border: 'none',
        background: theme.palette.background.default,
        top: 0,
        bottom: 0,
        right: drawerPosition === 'fixed' ? theme.spacing(3) : 0,
        overflowY: 'scroll',
        position: drawerPosition,
        paddingTop: theme.spacing(2),
        zIndex: theme.zIndex.appBar - 10,
        alignSelf: 'flex-start',
        [theme.breakpoints.down(drawerFullScreenBreakpoint)]: {
          top: 0,
          width: '100%',
          maxWidth: '100%',
          position: 'fixed',
          zIndex: theme.zIndex.modal,
          paddingTop: 0,
          right: 0
        }
      }),
      ...(!previewDrawerOpen && {
        transition: theme.transitions.create('width', {
          duration: 0
        }),
        width: 0,
        zIndex: theme.zIndex.appBar - 10,
        overflowX: 'visible',
        overflowY: 'visible'
      })
    }
  };
};

const AdPreviewDrawer = ({
  children,
  previewDrawerOpen,
  togglePreviewDrawer,
  toggleButtonText = t('programPreviewDrawer:adPreview.button')
}: PreviewDrawerProps) => {
  const [drawerPosition, setDrawerPosition] = useState('absolute');
  const theme = useTheme();
  const drawerRef = useRef(null);

  const isLargeScreenWidthOrSmaller = useMediaQuery(
    `(max-width:${DRAWER_FULL_SCREEN_BREAKPOINT}px)`
  );
  const isLargeScreenWidthOrLarger = useMediaQuery(
    `(min-width:${DRAWER_FULL_SCREEN_BREAKPOINT}px)`
  );

  const positionDrawer = () => {
    if (drawerRef.current) {
      const rect = (drawerRef.current as HTMLElement).getBoundingClientRect();
      if (rect.top <= 0 && drawerPosition !== 'fixed') {
        setDrawerPosition('fixed');
      }
      if (window.scrollY < 105 && drawerPosition !== 'absolute') {
        setDrawerPosition('absolute');
      }
    }
  };

  useEffect(() => {
    if (
      (isLargeScreenWidthOrSmaller && previewDrawerOpen) ||
      (isLargeScreenWidthOrLarger && !previewDrawerOpen)
    ) {
      togglePreviewDrawer();
      positionDrawer();
    }
  }, [isLargeScreenWidthOrSmaller, isLargeScreenWidthOrLarger]);

  useEffect(() => {
    const throttledPositionDrawer = throttle(positionDrawer, 15);
    document.addEventListener('scroll', throttledPositionDrawer);

    return () => {
      document.removeEventListener('scroll', throttledPositionDrawer);
    };
  }, [positionDrawer]);

  return (
    <Drawer
      anchor="right"
      variant="permanent"
      open={previewDrawerOpen}
      sx={getPreviewDrawerStyles({
        theme,
        previewDrawerOpen,
        drawerPosition,
        drawerFullScreenBreakpoint: DRAWER_FULL_SCREEN_BREAKPOINT
      })}
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{ ref: drawerRef }}
    >
      <Toolbar />
      {isLargeScreenWidthOrSmaller && (
        <DrawerToggleButton
          onClick={togglePreviewDrawer}
          isOpen={previewDrawerOpen}
          text={toggleButtonText}
        />
      )}
      <Box
        sx={theme => ({
          pr: 0,
          pl: 0,
          pb: 4,
          pt: 0,
          overflowY: 'scroll',
          width: '100%',
          position: 'relative',
          [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
            pr: 0,
            pt: 3,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            minHeight: '100vh'
          },
          [theme.breakpoints.between(0, theme.evSizes.previewDrawerWidth)]: {
            px: 2,
            margin: 0
          }
        })}
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
              maxWidth: theme.evSizes.previewDrawerWidth,
              margin: '0 auto',
              width: '100%'
            }
          })}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default AdPreviewDrawer;
