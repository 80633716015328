import { useState, useEffect } from 'react';
import { t } from 'i18next';

import { Box, Button, styled, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import Modal, { ModalHeader } from 'src/components/Modal';
import GuestMagicLinkForm from 'src/components/AuthGuestModal/GuestMagicLinkForm';

import {
  AUTH_CHOICES,
  AuthChoices,
  SHOW_GUEST_AUTH_MODAL_EVENT,
  GUEST_AUTH_CHOICE_EVENT,
  HIDE_GUEST_AUTH_MODAL_EVENT
} from './constants';

const ButtonContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  maxWidth: '400px',
  minWidth: '250px'
}));

const AuthGuestModal = () => {
  const [open, setOpen] = useState(false);
  const [showGuestForm, setShowGuestForm] = useState(false);

  useEffect(() => {
    const handleShowModal = () => {
      setOpen(true);
    };

    const handleHideModal = () => {
      setOpen(false);
    };

    window.addEventListener(SHOW_GUEST_AUTH_MODAL_EVENT, handleShowModal);
    window.addEventListener(HIDE_GUEST_AUTH_MODAL_EVENT, handleHideModal);
    return () => {
      window.removeEventListener(SHOW_GUEST_AUTH_MODAL_EVENT, handleShowModal);
      window.removeEventListener(HIDE_GUEST_AUTH_MODAL_EVENT, handleHideModal);
    };
  }, []);

  const handleChoice = (choice: AuthChoices) => {
    if (choice === AUTH_CHOICES.guest) {
      setShowGuestForm(true);
    }
    if (choice === AUTH_CHOICES.login) {
      // dispatch event for login choice
      window.dispatchEvent(
        new CustomEvent(GUEST_AUTH_CHOICE_EVENT, {
          detail: { choice: AUTH_CHOICES.login }
        })
      );
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      showClose={false}
      HeaderComponent={
        showGuestForm ? (
          <ModalHeader subHeaderText={t('guestAuth.modal.subHeaderTextGuest')}>
            {t('guestAuth.modal.headerTextGuest')}
          </ModalHeader>
        ) : (
          <ModalHeader subHeaderText={t('guestAuth.modal.subHeaderText')}>
            {t('guestAuth.modal.headerText')}
          </ModalHeader>
        )
      }
    >
      <Box>
        {/* Guest Magic Link form */}
        {showGuestForm && (
          <Box>
            <ButtonContainer sx={{ py: 1 }}>
              <GuestMagicLinkForm />
            </ButtonContainer>

            <Button
              fullWidth
              variant="text"
              onClick={() => setShowGuestForm(false)}
              sx={{ mt: 1 }}
            >
              {t('guestAuth.modal.backButton')}
            </Button>
          </Box>
        )}
        {/* Login options */}
        {!showGuestForm && (
          <Box sx={{ display: 'flex', gap: 2, my: 2, flexDirection: 'column' }}>
            <ButtonContainer>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircleIcon
                  sx={{ mr: 1, color: theme => theme.palette.grey[600] }}
                />
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {t('guestAuth.modal.loginButtonText')}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ py: 1 }}>
                {t('guestAuth.modal.loginButtonSubText')}
              </Typography>

              <Button
                fullWidth
                variant="contained"
                onClick={() => handleChoice(AUTH_CHOICES.login)}
              >
                {t('guestAuth.modal.loginButton')}
              </Button>
            </ButtonContainer>

            <ButtonContainer>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SupervisedUserCircleIcon
                  sx={{ mr: 1, color: theme => theme.palette.grey[600] }}
                />
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {t('guestAuth.modal.guestButtonText')}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ py: 1 }}>
                {t('guestAuth.modal.guestButtonSubText')}
              </Typography>

              <Button
                fullWidth
                variant="outlined"
                onClick={() => handleChoice(AUTH_CHOICES.guest)}
              >
                {t('guestAuth.modal.guestButton')}
              </Button>
            </ButtonContainer>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AuthGuestModal;
