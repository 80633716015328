import { useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { t } from 'i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Box, Button, Divider, Typography } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import SentryUtil from 'src/common/SentryUtil';
import CardOffer from 'src/components/Checkout/CardOffer';
import CreditCardProvider from 'src/components/Checkout/CreditCardProvider';
import { coMarketingShare } from 'src/pages/Guest/Invite/Constants';
import { GuestCoMarketOrderInviteDetails } from 'src/generated/gql/graphql';
import { formatPriceWithCents } from 'src/common/numbers';
import {
  generateLinkPath,
  generateLinkPathWithQueryParams
} from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { attachGuestPaymentToCoMarketOrder } from 'src/pages/Guest/Invite/mutations';

interface GuestPaymentFormData {
  spendStep: {
    paymentMethodId: string;
    stripeSourceId: string;
  };
}

interface GuestPaymentFormProps {
  guestOrderDetails: GuestCoMarketOrderInviteDetails;
  orderId: string;
}

const GuestPaymentForm = ({
  guestOrderDetails,
  orderId
}: GuestPaymentFormProps) => {
  const appSettings = useAppSettings();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [attachGuestPaymentToCoMarketOrderMutation] = useMutation(
    attachGuestPaymentToCoMarketOrder
  );
  // TODO: add this once we have the mutation
  // const [declineInviteMutation] = useMutation(declineInvite);

  const stripeKey = appSettings?.app?.general?.stripeKey;
  const stripePromise = useMemo(() => loadStripe(stripeKey), []);

  const formMethods = useForm<GuestPaymentFormData>({
    mode: 'all',
    shouldUnregister: false,
    resetOptions: {
      keepDefaultValues: true,
      keepValues: true
    }
  });

  const hasPaymentMethod = useWatch({
    control: formMethods.control,
    name: 'spendStep.paymentMethodId'
  });
  const hasStripeSourceId = useWatch({
    control: formMethods.control,
    name: 'spendStep.stripeSourceId'
  });

  const onSubmit = async (data: GuestPaymentFormData) => {
    const { paymentMethodId } = data?.spendStep;

    try {
      const response = await attachGuestPaymentToCoMarketOrderMutation({
        variables: {
          input: {
            orderId,
            paymentMethodId
          }
        }
      });
      const programPath = generateLinkPathWithQueryParams(
        paths.guest.program.base,
        {
          orderId
        },
        {
          architectureId:
            response.data?.attachGuestPaymentToCoMarketOrder.architectureId
        }
      );
      // TODO: add instrumentation for this error
      history.push(programPath);
    } catch (error) {
      // TODO: add instrumentation for this error
      enqueueSnackbar(t('guestInvite.error.payment'), {
        variant: 'error'
      });
      SentryUtil.addBreadcrumb({
        message: 'Error attaching guest payment to co-market order'
      });
      SentryUtil.captureException(error as Error);
    }
  };

  const onDecline = () => {
    // TODO: call mutation to decline the payment
    // try {
    //   await declineInviteMutation({
    //     variables: {
    //       input: {
    //         orderId
    //       }
    //     }
    //   });
    // } catch (error) {
    //   SentryUtil.addBreadcrumb({
    //     message: 'Error declining invite'
    //   });
    //   SentryUtil.captureException(error as Error);
    // }

    const programPath = generateLinkPath(paths.guest.programs.base);
    history.push(programPath);
  };

  const submitDisabled = !hasPaymentMethod || !hasStripeSourceId;

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Elements stripe={stripePromise}>
          <CreditCardProvider>
            <CardOffer selectThreshold={0} />
          </CreditCardProvider>
        </Elements>

        <Box sx={{ mt: 0 }}>
          <Divider sx={{ mb: 3 }} />

          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: theme => theme.palette.grey[600]
            }}
          >
            {t('guestInvite.paymentForm.totalBudget')}
            <span>
              {formatPriceWithCents(guestOrderDetails.totalAmountDue)}
            </span>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: theme => theme.palette.grey[600],
              mt: 1
            }}
          >
            {t('guestInvite.paymentForm.coMarketingShare', {
              coMarketingShare
            })}
            <span>
              {formatPriceWithCents(guestOrderDetails.guestAmountDue)}
            </span>
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography
            variant="h6"
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {t('guestInvite.paymentForm.totalDue')}
            <span>
              {formatPriceWithCents(guestOrderDetails.guestAmountDue)}
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3
          }}
        >
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitDisabled}
          >
            {t('guestInvite.paymentForm.submit')}
          </Button>
          <Button
            color="primary"
            sx={{
              textTransform: 'initial'
            }}
            onClick={onDecline}
          >
            {t('guestInvite.paymentForm.decline')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default GuestPaymentForm;
