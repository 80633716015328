export const AUTH_CHOICES = {
  guest: 'guest',
  login: 'login'
} as const;

export type AuthChoices = keyof typeof AUTH_CHOICES;

export const SHOW_GUEST_AUTH_MODAL_EVENT = 'SHOW_GUEST_AUTH_MODAL';
export const HIDE_GUEST_AUTH_MODAL_EVENT = 'HIDE_GUEST_AUTH_MODAL';
export const GUEST_AUTH_CHOICE_EVENT = 'GUEST_AUTH_CHOICE';
