import { t } from 'i18next';

import { Stepper, Step, StepLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface GuestInviteStepperProps {
  activeStep?: number;
}

const GuestInviteStepper = ({ activeStep = 0 }: GuestInviteStepperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stepper
      orientation="horizontal"
      nonLinear
      activeStep={activeStep}
      sx={{
        background: 'inherit',
        padding: isMobile ? 0 : `0 ${theme.spacing(2)}`,
        minWidth: '500px',
        display: 'flex',
        mb: 5
      }}
      {...(isMobile ? { connector: null } : {})}
    >
      <Step completed={activeStep > 0} key="guestInviteStep-1" disabled>
        <StepLabel
          sx={{
            cursor: 'pointer',
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              '& .MuiStepLabel-labelContainer': {
                padding: 0
              }
            },
            '& .MuiStepLabel-iconContainer': {
              [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                paddingBottom: theme.spacing(1)
              }
            }
          }}
        >
          {t('guestInvite.step1')}
        </StepLabel>
      </Step>
      <Step key="guestInviteStep-2" disabled>
        <StepLabel
          sx={{
            cursor: 'pointer',
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              '& .MuiStepLabel-labelContainer': {
                padding: 0
              }
            },
            '& .MuiStepLabel-iconContainer': {
              [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                paddingBottom: theme.spacing(1)
              }
            }
          }}
        >
          {t('guestInvite.step2')}
        </StepLabel>
      </Step>
    </Stepper>
  );
};

export default GuestInviteStepper;
