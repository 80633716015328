import { FormProvider, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { Box, Button } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import DynamicForm from 'src/components/ReduxForm/DynamicForm/DynamicForm';
import { modifyGuestUserProfile } from 'src/pages/Guest/Invite/mutations';
import { profileType } from 'src/pages/Guest/Invite/Constants';
import { UserProfile } from 'src/generated/gql/graphql';

interface GuestDetailsFormProps {
  onSuccess: () => void;
  onError: () => void;
  userProfileData: UserProfile;
}

const GuestDetailsForm = ({
  onSuccess,
  onError,
  userProfileData
}: GuestDetailsFormProps) => {
  const appSettings = useAppSettings();
  const { enqueueSnackbar } = useSnackbar();

  const [modifyGuestUserProfileMutation] = useMutation(modifyGuestUserProfile);

  const userProfileType = appSettings?.organizationUserProfileTypes?.find(
    profile => profile?.type === profileType
  );

  const userProfileInputs = userProfileType?.fields?.map(field => {
    return {
      ...field,
      name: field.userProfileFieldName
    };
  });

  const formMethods = useForm<UserProfile>({
    mode: 'all',
    defaultValues: userProfileData || {}
  });

  const onSubmit = async (data: UserProfile) => {
    try {
      await modifyGuestUserProfileMutation({
        variables: {
          isActive: true,
          profileData: data,
          profileType
        }
      });

      onSuccess();
    } catch (error) {
      enqueueSnackbar(t('guestInvite.error.profileUpdate'), {
        variant: 'error'
      });
      onError();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <DynamicForm isHookForm inputs={userProfileInputs} />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          {t('guestInvite.submit')}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default GuestDetailsForm;
