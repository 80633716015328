import { gql } from 'src/generated/gql';

export const guestCoMarketOrderDetailsAndPreview = gql(`
  query GuestCoMarketOrderDetailsAndPreview($orderId: ID!) {
    guestCoMarketOrderDetailsAndPreview(orderId: $orderId) {
      programName
      programStart
      programEnd
      totalAmountDue
      guestAmountDue
      hostName
      hostEmail
      inviteExpiresInDays
      adPreviewJobId
      inviteStatus
      coMarketOrderStatus
      channels
    }
  }
`);

export const getGuestUserProfile = gql(`
  query GetGuestUserProfile($userProfileType: String!) {
    me {
      userProfile(userProfileType: $userProfileType) {
        organizationUserProfileTypeId
        userProfileType
        profileData
        updatedAt
      }
    }
  }
`);
