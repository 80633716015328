import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormContext } from 'react-hook-form';

import { Architecture, Blueprint } from 'src/generated/gql/graphql';
import { getOneOfEachChannelKeys } from 'src/common/blueprints';

import SaveDraftButton, {
  DRAFT_TYPES
} from 'src/pages/Program/components/SaveDraftButton';
import { HelpTip } from 'src/components/Icons';

import { programActions } from './Constants';
import { useImportUserContext } from '../Chrome/Chrome';
import TooltipButton from '../../components/Buttons/TooltipButton';
import FooterValidationErrors, {
  ChannelValidationErrors
} from './FooterValidationErrors';
import useProgram from './utils/useProgram';

const pageText = () => ({
  formErrorsSnackbar: t('programStepFooter:snackbar.formErrors'),
  importingUser: t('programStepFooter:nextButton.importingUser'),
  createAutomationButton: t('programStepFooter:button.createAutomation'),
  saveAutomationButton: t('programStepFooter:button.saveAutomation'),
  checkoutButton: t('programStepFooter:button.checkout'),
  nextButton: t('programStepFooter:button.next'),
  backButton: t('programStepFooter:button.back'),
  proceedButton: t('programStepFooter:button.proceed'),
  purchaseNowButton: t('programStepFooter:button.purchaseNow'),
  coMarketingButton: t('programStepFooter:button.coMarketing'),
  coMarketingTip: t('programStepFooter:totalCoMarketingTip')
});

export type SkipStep = {
  contentVerified?: boolean;
  targetStep: number;
  currentStep: number;
};

export type SetSkipStep = (skipStep: SkipStep | null) => void;

type HandleNext = (args: {
  currentStep: number;
  formStepErrors: object;
}) => void;

interface ProgramStepFooterProps {
  submitting: boolean;
  formStepErrors: Record<string, any>;
  disabled?: boolean;
  handleNextWithValidation?: (
    successCallback: () => void,
    errorCallback?: () => void
  ) => Promise<void>;
  loading: boolean;
  type: string;
  skipStep: SkipStep | null;
  setSkipStep: SetSkipStep;
  blueprint: { id: string; blueprint: Blueprint };
  architecture: Architecture;
  submitForm?: (formValues: Record<string, any>) => void;
  handleNext: HandleNext;
  lockSubmit: boolean;
  showValidationErrors: () => void;
  channelValidationErrors?: ChannelValidationErrors | null;
  isChannelValidationLoading?: boolean;
  submitDisabled: boolean;
  selectedBusinessObjects: any;
  isCoMarketing?: boolean;
}

const ProgramStepFooter = ({
  // submitting is passed from parent
  // isSubmitting is the formState
  submitting = false,
  formStepErrors,
  disabled = false,
  handleNextWithValidation,
  loading,
  type,
  skipStep,
  setSkipStep,
  blueprint,
  architecture,
  submitForm,
  handleNext,
  lockSubmit,
  showValidationErrors,
  channelValidationErrors,
  isChannelValidationLoading,
  submitDisabled,
  selectedBusinessObjects,
  isCoMarketing
}: ProgramStepFooterProps) => {
  const {
    programStepper: { steps, stepRefs, currentStep, selectExactStep },
    isAutomated,
    isAutomatedEdit
  } = useProgram();

  const {
    getValues,
    formState: { isSubmitting, errors: syncErrors, isValidating }
  } = useFormContext();

  const isFirstStep = currentStep === 0;
  const isLastStep = steps.length - 1 === currentStep;
  const text = pageText();

  const trackingData = {
    architectureId: architecture?.id,
    productId: blueprint?.id,
    channel: getOneOfEachChannelKeys(blueprint?.blueprint?.channels)
  };

  const { importingUser } = useImportUserContext();

  useEffect(() => {
    if (!skipStep || !skipStep?.contentVerified) {
      return;
    }
    // check for sync errors and then stop or stop if we hit the target step.
    if (currentStep === skipStep.targetStep || !isEmpty(syncErrors)) {
      setSkipStep(null);
      return;
    }

    if (skipStep && !loading && !lockSubmit) {
      if (currentStep === skipStep.currentStep) {
        if (handleNextWithValidation) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          handleNextWithValidation(
            () => handleNext({ currentStep, formStepErrors }),
            () => setSkipStep(null)
          );
        } else {
          handleNext({ currentStep, formStepErrors });
        }
      }
    }
  }, [skipStep, loading]);

  const isLoading = loading || isSubmitting || submitting;

  return (
    <Box>
      {channelValidationErrors && (
        <FooterValidationErrors
          channelValidationErrors={channelValidationErrors}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          paddingTop: 2,
          gap: 1,
          justifyContent: 'flex-end'
        }}
      >
        {!isFirstStep && (
          <LoadingButton
            disabled={isFirstStep}
            onClick={() => {
              if (stepRefs?.[currentStep - 1]?.current) {
                // Scroll to top of last section
                stepRefs[currentStep - 1]?.current?.scrollIntoView();
              }
              // select the previous step
              selectExactStep(currentStep - 1, trackingData);
            }}
            data-cy="step-footer-back"
            id="step-footer-back-button"
          >
            {text.backButton}
          </LoadingButton>
        )}
        {!isAutomatedEdit && (
          <SaveDraftButton
            getFormValues={getValues}
            selectedBusinessObjects={selectedBusinessObjects}
            type={isAutomated ? DRAFT_TYPES.AUTOMATION : DRAFT_TYPES.PROGRAM}
          />
        )}
        {!isLastStep && (
          <TooltipButton
            displayTooltip={importingUser}
            tooltip={text.importingUser}
            loading={isChannelValidationLoading || isValidating}
            disabled={
              lockSubmit ||
              disabled ||
              isLoading ||
              importingUser ||
              channelValidationErrors ||
              isChannelValidationLoading ||
              isValidating
            }
            variant="contained"
            color="primary"
            onClick={() => {
              return handleNextWithValidation
                ? handleNextWithValidation(() =>
                    handleNext({ currentStep, formStepErrors })
                  )
                : handleNext({ currentStep, formStepErrors });
            }}
            data-cy="step-footer-next"
            id="step-footer-next-button"
          >
            {text.proceedButton}
          </TooltipButton>
        )}
        {isLastStep && (
          <LoadingButton
            type="button"
            variant="contained"
            color="primary"
            loading={isLoading || isValidating}
            disabled={disabled || isLoading || submitDisabled}
            data-cy="step-footer-checkout"
            id="step-footer-next-button"
            {...(submitForm && handleNextWithValidation
              ? {
                  onClick: () => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    handleNextWithValidation(() => {
                      if (!isEmpty(formStepErrors)) {
                        showValidationErrors();
                      } else {
                        const formValues = getValues();
                        submitForm(formValues);
                      }
                    });
                  }
                }
              : {})}
          >
            {isCoMarketing ? (
              text.coMarketingButton
            ) : (
              <>
                {type === programActions.automatedCreate &&
                  text.createAutomationButton}
                {type === programActions.automatedEdit &&
                  text.saveAutomationButton}
                {type === programActions.create && text.purchaseNowButton}
              </>
            )}
          </LoadingButton>
        )}
        {isCoMarketing && <HelpTip tipText={text.coMarketingTip} />}
      </Box>
    </Box>
  );
};

export default ProgramStepFooter;
