import { useQuery } from '@apollo/client';

import { getBulkPreviewJob } from 'src/components/AdPreview/queries';
import { generateAdPreviewFromResponse } from 'src/components/AdPreview/BulkAdPreview';
import AdPreviewSkeleton from 'src/components/AdPreview/AdPreviewSkeleton';

const GuestAdPreview = ({
  adPreviewJobId,
  channels
}: {
  adPreviewJobId: string;
  channels: string[];
}) => {
  const { data, loading: loadingAdPreviewSet } = useQuery(getBulkPreviewJob, {
    variables: { jobId: adPreviewJobId },
    skip: !adPreviewJobId
  });

  const adPreviews = data?.getBulkPreviewJob?.previewSets;

  if (loadingAdPreviewSet) {
    return (
      <AdPreviewSkeleton
        channel={channels[0]}
        hasUniformPreviewWidth={false}
        isResponsive
      />
    );
  }

  const AdPreview = generateAdPreviewFromResponse({
    adPreviewSet: adPreviews?.[0]?.previews?.[0],
    contentName: '[Content Name]',
    displayAsPhoneMockUp: true,
    disableResponsiveStyles: true,
    hasUniformPreviewWidth: true,
    isResponsive: true
  });

  return <div>{AdPreview}</div>;
};

export default GuestAdPreview;
