import { isObject, flatMap, some, includes } from 'lodash';

export const paths = {
  home: '/',
  dashboard: {
    base: '/dashboard',
    leads: '/dashboard/contacts',
    ads: '/dashboard/ads',
    gallery: '/dashboard/gallery',
    audiences: '/dashboard/audiences'
  },
  facebook: {
    pages: '/facebook/pages',
    link: '/facebook/new',
    status: '/facebook/status/:id'
  },
  architecture: {
    base: '/architecture/:architectureId',
    content: '/architecture/:architectureId/content',
    program: '/architecture/:architectureId/programs/:orderId',
    programs: '/architecture/:architectureId/programs',
    programCreate: '/architecture/:architectureId/programCreate',
    programEdit: '/architecture/:architectureId/programs/:programId/edit',
    automatedProgram:
      '/architecture/:architectureId/automatedPrograms/:programId',
    automatedPrograms: '/architecture/:architectureId/automatedPrograms',
    automatedProgramCreate:
      '/architecture/:architectureId/automatedProgramCreate',
    automatedProgramEdit:
      '/architecture/:architectureId/automatedProgramEdit/:programId',
    multiLocationProgram: `/architecture/:architectureId/multiLocationPrograms/:orderId`,
    multiLocationProgramEdit: `/architecture/:architectureId/multiLocationPrograms/:orderId/edit`,
    settings: '/architecture/:architectureId/settings'
  },
  programs: {
    base: '/programs'
  },
  automations: {
    base: '/automations'
  },
  account: {
    base: '/account',
    notifications: '/account/notifications',
    leadAlertPreferences: '/account/leadAlertPreferences',
    billing: '/account/billing',
    localization: '/account/localization'
  },
  auth: {
    login: '/login',
    sso: '/sso',
    logout: '/logout',
    callBack: '/authenticate',
    activate: {
      base: '/activate',
      confirm: '/activate/confirm',
      check: '/activate/check'
    }
  },
  playground: {
    base: '/playground',
    testing: {
      base: '/playground/testing',
      inputs: '/playground/testing/inputs/:componentIdentifier'
    }
  },
  admin: {
    base: '/admin',
    impersonate: '/admin/impersonate',
    blueprintBuilderOverview: '/admin/blueprintBuilder',
    blueprintBuilder: '/admin/blueprintBuilder/:productId',
    createOrganization: '/admin/createOrganization',
    createUser: '/admin/createUser',
    settings: {
      base: '/admin/settings',
      architecture: '/admin/settings/architecture',
      authentication: '/admin/settings/authentication',
      billing: '/admin/settings/billing',
      catalogContentLanding: '/admin/settings/catalogContent',
      catalogContent:
        '/admin/settings/catalogContent/:architectureId/:catalogId',
      contentTablesLanding: '/admin/settings/contentTables',
      contentTables: '/admin/settings/contentTables/:contentTable',
      facebook: '/admin/settings/facebook',
      skin: '/admin/settings/skin',
      webhook: '/admin/settings/webhook',
      promotions: '/admin/settings/promotions',
      promotionDetails: '/admin/settings/promotions/:promoId',
      promotionCreate: '/admin/settings/createPromotion',
      galleries: '/admin/settings/galleries',
      galleryCreate: '/admin/settings/createGallery',
      galleryDetails: '/admin/settings/galleries/:galleryId',
      resourceLibrary: '/admin/settings/resourceLibrary',
      googleSearch: '/admin/settings/googleSearch',
      contentDataManager: '/admin/settings/content/manage',
      channelSpendAllocation: '/admin/settings/channelSpendAllocation',
      pricing: '/admin/settings/pricing',
      experiments: '/admin/settings/experiments',
      quickStarts: '/admin/settings/quickstarts',
      quickStartEdit: '/admin/settings/quickstarts/:quickStartId',
      notificationPreferences: '/admin/settings/notificationPreferences'
    },
    tools: {
      base: '/admin/tools',
      facebook: '/admin/tools/facebook',
      partner: '/admin/tools/partner',
      audience: '/admin/tools/audience',
      manuallyLinkOrders: '/admin/tools/manuallyLinkOrders',
      cancelOrder: '/admin/tools/cancelOrder'
    },
    developer: {
      clientKeys: '/admin/developer/clientKeys',
      handlebarMapper: '/admin/developer/handlebarMapper'
    },
    utilities: {
      base: '/admin/utilities',
      imageTemplateBuilder: '/admin/utilities/imageTemplateBuilder'
    },
    setUser: '/admin/setUser'
  },
  partner: {
    base: '/partner',
    portalLink: '/partner/portalLink',
    onboarding: {
      stripe: '/partner/onboarding/stripeConnect',
      stripeReturn: '/partner/onboarding/stripeConnect/return',
      stripeRefresh: '/partner/onboarding/stripeConnect/refresh',
      tiktok: '/partner/onboarding/tiktok',
      okta: '/partner/onboarding/okta'
    },
    emailNotificationSetup: '/partner/emailNotificationSetup',
    tiktok: '/partner/tiktok'
  },
  // Co-marketing guest paths
  guest: {
    base: '/guest',
    auth: {
      base: '/guest/authenticate'
    },
    leads: {
      base: '/guest/leads'
    },
    programs: {
      base: '/guest/programs'
    },
    program: {
      base: '/guest/program/:orderId'
    },
    account: {
      base: '/guest/account'
    },
    invite: {
      base: '/guest/invite/:orderId'
    }
  },
  unsubscribe: '/unsubscribe',
  plg: {
    signUp: '/signup'
  }
} as const;

const mapRoutesDeep = (
  routesObj: Record<string, Record<string, string> | string>
): string[] => {
  return flatMap(routesObj, val => {
    if (isObject(val)) {
      return mapRoutesDeep(val);
    }
    return val;
  });
};

export const plgPaths = mapRoutesDeep(paths.plg);

export const isPlgPath = (path: string) =>
  some(plgPaths, route => includes(route, path));

export const allowListPaths = [
  paths.auth.activate.base,
  paths.auth.activate.check,
  paths.auth.activate.confirm,
  paths.auth.logout,
  paths.auth.sso,
  paths.admin.setUser,
  paths.unsubscribe,
  // guest auth paths
  paths.guest.auth.base,
  // add all the partner paths here
  ...mapRoutesDeep(paths.partner),
  ...plgPaths
];
