import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Box, Link } from '@mui/material';

import { GuestCoMarketOrderInviteDetails } from 'src/generated/gql/graphql';
import GenericPlaceholder from 'src/components/Placeholders/GenericPlaceholder';

interface GuestInviteExpiredProps {
  guestOrderDetails: GuestCoMarketOrderInviteDetails;
}

const GuestInviteExpired = ({ guestOrderDetails }: GuestInviteExpiredProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <GenericPlaceholder
        variant="noProgramsGuest"
        title={t('guestInvite.guestInviteExpiredTitle')}
        subtitle={
          <Trans
            i18nKey="guestInvite.guestInviteExpired"
            values={{
              hostName: guestOrderDetails.hostName,
              hostEmail: guestOrderDetails.hostEmail
            }}
            components={[
              <Link
                href={`mailto:${guestOrderDetails.hostEmail}`}
                key="hostEmail"
                component="a"
              />
            ]}
          />
        }
      />
    </Box>
  );
};

export default GuestInviteExpired;
