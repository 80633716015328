/* eslint-disable class-methods-use-this */
import { isNil } from 'lodash';
import { paths } from 'src/routes/paths';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';

import Auth0Authenticator from './Auth0Authenticator';
import SSOAuthenticator from './SSOAuthenticator';

import {
  AUTH0_KEYS,
  AUTH_PROVIDER_TYPES,
  getAuthUrl,
  getCurrentLocation,
  isGroupAdmin,
  isOrgAdmin,
  setAuthUrl,
  setAccessTokenExpiration,
  setAccessToken,
  setEvAccessToken,
  deleteAuthUrl
} from './common';
import { getContextFromJwt } from './AuthUtil';
import SentryUtil from '../common/SentryUtil';

const AUTH0_AUTH_PARAMS = [
  new RegExp('.*access_token=.*'),
  new RegExp('.*scope=.*'),
  new RegExp('.*expires_in=.*'),
  new RegExp('.*token_type=.*'),
  new RegExp('.*state=.*'),
  new RegExp('.*id_token=.*')
];

// Check that each of the authentication parameters are present in the current
// path since Auth0 can't seem to handle sending users to our /#/authenticate
// path due to their inability ot handle '#' in the passback URLs.
const isAuth0AuthUrl = () => {
  const path = `${window.location}`;

  for (let i = 0; i < AUTH0_AUTH_PARAMS.length; i++) {
    if (!path.match(AUTH0_AUTH_PARAMS[i])) {
      return false;
    }
  }

  return true;
};

class Auth {
  initialize(initParams) {
    const {
      allowList = [],
      appSettings,
      organizationId,
      organizationDomainId,
      history,
      match,
      location
      // these params forwarded to the auth handler init function
      // here for reference:
      // features,
    } = initParams;

    const authenticatorName =
      appSettings?.app?.auth?.authProviderType || 'auth0';
    this.allowList = allowList;
    this.appSettings = appSettings;
    this.organizationId = organizationId;
    this.organizationDomainId = organizationDomainId;
    this.history = history;
    this.match = match;
    this.location = location;

    switch (authenticatorName) {
      // just a simple token exchange auth handled by SSO
      case AUTH_PROVIDER_TYPES.internal:
      case AUTH_PROVIDER_TYPES.okta_direct_sso:
        this.authenticator = new SSOAuthenticator();
        break;
      case AUTH_PROVIDER_TYPES.auth0:
      case AUTH_PROVIDER_TYPES.auth0_sso:
      // falls through
      default:
        this.authenticator = new Auth0Authenticator();
    }

    this.authenticator.initialize(initParams);

    return this;
  }

  getAuthUrl = () => {
    return getAuthUrl();
  };

  setAuthUrl = url => {
    setAuthUrl(url);
  };

  // this should be the only function we call when a user is not authenticated
  // before we sometimes called lithiumShowLoginPrompt
  login() {
    // check allowlist urls first. skip login if listed
    if (
      this.allowList.filter(urls =>
        getCurrentLocation().match(new RegExp(urls, 'gi'))
      ).length ||
      isAuth0AuthUrl()
    ) {
      return;
    }

    // if it's not set we should hold onto the current page so we can redirect
    // including search params for deep linking after auth...
    if (
      // it's not already been defined
      isNil(getAuthUrl()) &&
      // if we are on staging-pr skip the allowlist
      (window.location.origin.includes('office-staging-pr') ||
        // window.location.origin.includes('lilo.evocalize') ||
        // and often tokens are sent to '/' if not the sso page
        (this.location.pathname !== paths.home &&
          // even though it's the default we don't skip the dashboard for utm query params etc.
          // this.location.pathname !== paths.dashboard.base &&
          // we aren't on any login, redirect or auth pages
          !this.location.pathname.includes(paths.auth.login) &&
          !this.location.pathname.includes(paths.auth.logout) &&
          !this.location.pathname.includes(paths.auth.sso) &&
          !this.location.pathname.includes(paths.auth.callback) &&
          !this.location.pathname.includes(paths.auth.activate.base) &&
          !this.location.pathname.includes(paths.plg.signUp)))
    ) {
      setAuthUrl(
        `${window.location.origin}${window.location.pathname}#${this.location.pathname}${this.location.search}`
      );
    }

    return this.authenticator.login();
  }

  postLoginCleanup() {
    // if we are logged when okta sends us here
    // we don't need this okta re-direct token anymore
    const query = queryString.parse(this.location.search);
    if (query.iss) {
      delete query.iss;
      let search = '';
      if (Object.keys(query).length) {
        search = `?${queryString.stringify(query)}`;
      }
      this.history.replace(`${this.location.pathname}${search}`);
    }
  }

  manualLogin(username, password) {
    return this.authenticator.manualLogin(username, password);
  }

  lithiumShowLoginPrompt() {
    this.authenticator.lithiumShowLoginPrompt();
  }

  exchangeToken(authTokenOverride, orgId, orgDomainId) {
    return this.authenticator.exchangeToken(
      authTokenOverride,
      orgId,
      orgDomainId
    );
  }

  exchangeGuestToken(authTokenOverride, fqdn) {
    const exchangeParams = `fqdn: "${fqdn}", authString: "${authTokenOverride}"`;

    const authQuery = `
      mutation AuthorizeGuest {
          authorizeGuest(${exchangeParams}) {
              token
          }
      }
    `;

    return fetch(this.appSettings.app.general.baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: authQuery })
    })
      .then(response => response.json())
      .then(({ data }) => {
        const response = data?.authorizeGuest;
        const token = response?.token;

        if (!token) {
          return Promise.reject(response);
        }

        let exp = null;

        try {
          const decoded = jwtDecode(token);
          exp = Number(decoded.exp) * 1000;
        } catch (err) {
          // Do nothing - our exp check below will catch issues.
        }

        if (!exp) {
          return Promise.reject(response);
        }

        // Now that we've verified that exchange worked successfully,
        // we can set the tokens in localStorage
        setAccessTokenExpiration(exp);
        setAccessToken(authTokenOverride);
        setEvAccessToken(token);

        // Delete the auth url from localStorage routing will be handled by the GuestAuthLanding
        deleteAuthUrl();

        return Promise.resolve({ success: true });
      })
      .catch(err => {
        const context = getContextFromJwt(authTokenOverride);
        const tokenSub = context?.sub;

        // Send error information off to Sentry.
        SentryUtil.addBreadcrumb({
          category: 'auth',
          data: {
            tokenSub,
            message: 'AuthorizeGuest: exchangeToken failed',
            errObject: err
          }
        });
        SentryUtil.captureException(err);
        // trigger the catch in  for failed login
        return Promise.reject(new Error('InvalidToken'));
      });
  }

  logout(returnTo) {
    return this.authenticator.logout(returnTo);
  }

  isOrgAdmin() {
    return isOrgAdmin();
  }

  isGroupAdmin() {
    return isGroupAdmin();
  }

  // Note: this will always return false for now until we add support for
  //       corporate-level admins later.
  isCorporateAdmin() {
    return false;
  }

  setAllTokens({ accessToken, idToken, evAccessToken, agentProfileId }) {
    this.authenticator.setAllTokens({
      accessToken,
      idToken,
      evAccessToken,
      agentProfileId
    });
  }

  switchOfficeToken(newOfficeId) {
    return this.authenticator.switchOfficeToken(newOfficeId);
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem(AUTH0_KEYS.EXPIRES_AT));

    return new Date().getTime() < expiresAt;
  };
}

export default new Auth();
