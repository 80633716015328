import { gql } from 'src/generated/gql';

export const modifyGuestUserProfile = gql(`
  mutation ModifyGuestUserProfile(
    $isActive: Boolean!
    $profileData: JSON!
    $profileType: UserProfileType
  ) {
    modifyUserProfile(input: { isActive: $isActive, profileData: $profileData, profileType: $profileType }) {
      organizationUserProfileTypeId
      userProfileType
      profileData
      updatedAt
    }
  }
`);

export const attachGuestPaymentToCoMarketOrder = gql(`
  mutation AttachGuestPaymentToCoMarketOrder(
    $input: AttachGuestPaymentToCoMarketOrderInput!
  ) {
    attachGuestPaymentToCoMarketOrder(input: $input) {
      architectureId
    }
  }
`);
