import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

import { styled } from '@mui/material/styles';

import { dayjs } from 'src/common/dates';
import { GuestCoMarketOrderInviteDetails } from 'src/generated/gql/graphql';
import { formatPriceWithCents } from 'src/common/numbers';
import PreviewDrawerHeader from 'src/pages/Program/ProgramPreviewDrawer/PreviewDrawerHeader';
import { coMarketingShare } from 'src/pages/Guest/Invite/Constants';
import AdPreviewDrawer, {
  DrawerDivider
} from 'src/components/AdPreview/AdPreviewDrawer';
import GuestAdPreview from 'src/pages/Guest/Invite/GuestAdPreview';

const LabelText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  display: 'grid',
  gridTemplateColumns: '150px 1fr',
  gap: theme.spacing(1),
  width: '100%'
}));

const ValueText = styled(Box)(({ theme }) => ({
  fontWeight: 'normal',
  color: theme.palette.grey[600],
  textAlign: 'left'
}));

interface GuestPreviewDrawerProps {
  previewDrawerOpen: boolean;
  setPreviewDrawerOpen: (open: boolean) => void;
  guestOrderDetails: GuestCoMarketOrderInviteDetails;
}

const GuestPreviewDrawer = ({
  previewDrawerOpen,
  setPreviewDrawerOpen,
  guestOrderDetails
}: GuestPreviewDrawerProps) => {
  const programName = guestOrderDetails?.programName;
  const guestAmountDue = guestOrderDetails?.guestAmountDue;
  const channels = guestOrderDetails?.channels;
  const adPreviewJobId = guestOrderDetails?.adPreviewJobId;

  const programStart = guestOrderDetails?.programStart;
  const programEnd = guestOrderDetails?.programEnd;
  const duration = dayjs(programEnd).diff(programStart, 'days');

  return (
    <AdPreviewDrawer
      toggleButtonText={t('guestInvite.previewDrawer.toggleText')}
      previewDrawerOpen={previewDrawerOpen}
      togglePreviewDrawer={() => setPreviewDrawerOpen(!previewDrawerOpen)}
    >
      <PreviewDrawerHeader isSummary={false} channels={channels as string[]} />
      <DrawerDivider />

      <Box sx={{ maxWidth: '360px', width: '100%', margin: '0 auto' }}>
        <Box sx={{ maxWidth: '360px', width: '100%' }}>
          <GuestAdPreview
            adPreviewJobId={adPreviewJobId}
            channels={channels as string[]}
          />
        </Box>
      </Box>

      <DrawerDivider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          maxWidth: 400,
          margin: '0 auto'
        }}
      >
        <LabelText variant="body1">
          <span>{t('guestInvite.previewDrawer.programName')}</span>
          <ValueText>{programName}</ValueText>
        </LabelText>
        <LabelText variant="body1">
          <span>
            {t('guestInvite.previewDrawer.coMarketingShare', {
              coMarketingShare
            })}
          </span>
          <ValueText>{formatPriceWithCents(guestAmountDue)}</ValueText>
        </LabelText>
        <LabelText variant="body1">
          <span>{t('guestInvite.previewDrawer.programDuration')}</span>
          <ValueText>
            {duration} {t('guestInvite.previewDrawer.days')}
          </ValueText>
        </LabelText>
      </Box>
      <DrawerDivider />
    </AdPreviewDrawer>
  );
};

export default GuestPreviewDrawer;
