import { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { t } from 'i18next';
import qs from 'qs';

import { Box, Typography } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import PageTitle from 'src/components/PageTitle';
import Loading from 'src/components/Loading';
import Auth from 'src/Auth/Auth';
import { paths } from 'src/routes/paths';
import {
  HIDE_GUEST_AUTH_MODAL_EVENT,
  SHOW_GUEST_AUTH_MODAL_EVENT
} from 'src/components/AuthGuestModal/constants';
import { isValidRedirectPath } from 'src/Auth/common';

const GuestAuthLanding = () => {
  const appSettings = useAppSettings();
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  const handleTokenExchange = () => {
    const { token, redirect } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });

    const organizationFqdn = appSettings?.organizationFqdn;

    Auth.exchangeGuestToken(token, organizationFqdn)
      .then(({ success }: { success: boolean }) => {
        if (!success) {
          setRedirectTo(null);
          window.dispatchEvent(new Event(SHOW_GUEST_AUTH_MODAL_EVENT));
          return;
        }

        // when we have a successful token exchange, we want to ensure the guest auth modal is hidden
        window.dispatchEvent(new Event(HIDE_GUEST_AUTH_MODAL_EVENT));

        // check for a redirect path
        if (redirect && typeof redirect === 'string') {
          if (isValidRedirectPath(redirect)) {
            return setRedirectTo(redirect);
          }
        }

        // default redirect if no redirect provided
        setRedirectTo(paths.guest.programs.base);
      })
      .catch(() => {
        setRedirectTo(null);
        window.dispatchEvent(new Event(SHOW_GUEST_AUTH_MODAL_EVENT));
      });
  };

  useEffect(() => {
    handleTokenExchange();
  }, []);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        gap: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        p: 1
      }}
    >
      <PageTitle subPageTitle={t('guestAuth.authenticate.pageTitle')} />
      {appSettings?.evAssets?.logoUrl && (
        <Box
          component="img"
          alt={`${appSettings?.app.general.baseTitle} Logo`}
          src={appSettings?.evAssets?.logoUrl}
          sx={{
            maxWidth: '300px'
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Loading />
        <Typography variant="h5">
          {t('guestAuth.authenticate.loggingIn')}
        </Typography>
      </Box>
    </Box>
  );
};

export default GuestAuthLanding;
