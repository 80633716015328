import { flow } from 'lodash';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { withRouter } from 'react-router-dom';
import { useWatch } from 'react-hook-form';

import { Box, Button, Divider, Grid, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

import { facebookCreativeTypes } from 'src/common/adChannels';
import AdPreview from 'src/components/AdPreview';
import { showBusinessObjectSelectorModal } from 'src/components/BusinessObjectSelector/actions';
import { useAppSettings } from 'src/AppSettings';
import { useGlobalContext } from 'src/GlobalContextProvider';
import AutomatedProgramName from 'src/pages/Program/ProgramName/AutomatedProgramName';
import useProgram from 'src/pages/Program/utils/useProgram';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import BulkAdPreview from 'src/components/AdPreview/BulkAdPreview';
import AdPreviewDrawer from 'src/components/AdPreview/AdPreviewDrawer';

import ProgramName from '../ProgramName/ProgramName';
import PreviewDrawerHeader from './PreviewDrawerHeader';
import SellingPointsList from './SellingPointsList';
import PreviewDrawerFooter from './PreviewDrawerFooter';
import {
  getProgramNameLabel,
  getProgramNameTranslated
} from '../ProgramName/ProgramNameUtil';
import {
  SummaryLabel,
  SummaryItems,
  EditableSummaryItem
} from './DrawerSummary';

export const DrawerDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0)
}));

const SelectButtonContainer = styled(Box)(() => ({ textAlign: 'center' }));

const pageText = ({ objectName }) => ({
  adPreviewToggle: t('programPreviewDrawer:adPreview.button'),
  orderSummaryToggle: t('programPreviewDrawer:orderSummary.button'),
  importingUser: t('programPreviewDrawer:importingUserMessage'),
  selectBlueprint: t('programPreviewDrawer:pleaseSelectBlueprint'),
  selectBusinessObject: t('programPreviewDrawer:pleaseSelectBusinessObject', {
    objectName
  }),
  previewLabel: t('programPreviewDrawer:summaryLabel.preview'),
  automationNameLabel: t('programPreviewDrawer:summaryLabel.automationName')
});

const ProgramPreviewDrawer = props => {
  const {
    showBusinessObjectSelectorModal,
    contentName = '',
    selectedBusinessObjects = null,
    loading,
    error,
    defaultFacebookPage = null,
    isContentSelectable,
    blueprintsLoading,
    conditionalInputsVisibility,
    handleAdContentChannelValidation,
    setIsPollingPreview,
    isPollingPreview,
    setIsValidatingCreative,
    clearUpdatedInputCreativeErrors,
    setCreativeValidationErrors
  } = props;
  const globalContext = useGlobalContext();
  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const architecture = useArchitecture();

  const {
    programStepper: { selectExactStep, currentStep },
    selectedBlueprint,
    isAutomated,
    contentColumns,
    previewDrawerOpen,
    togglePreviewDrawer,
    isMultiLocation,
    selectedLocation
  } = useProgram();

  const dynamicUserInputs = useWatch({ name: 'dynamicUserInputs' });
  const locationsOverrideById = useWatch({ name: 'locationsOverrideById' });
  const selectedLocations = useWatch({ name: 'selectedLocations' });
  const programName = useWatch({ name: 'spendStep.programName' });
  const automatedProgramName = useWatch({
    name: 'spendStep.automatedProgramName'
  });

  const displayNameTemplate = architecture?.catalog?.displayNameTemplate;

  const isSummary = currentStep === 1;

  const objectName = contentName || t('programPreviewDrawer:items');
  const text = pageText({ objectName });

  const isDrawerWidth = useMediaQuery(theme =>
    theme.breakpoints.between(0, theme.evSizes.previewDrawerWidth)
  );

  const appSettings = useAppSettings();
  const supportPhone = appSettings?.app?.support?.phone;
  const supportEmail = appSettings?.app?.support?.email;

  const goToStepOne = () => {
    // Note: Right now, we have hard-coded this to go to step 0 since that's
    //       where the blueprint selector lives. We might need to pass this
    //       in at some point.
    const trackingData = {
      architectureId: architecture?.id,
      productId: selectedBlueprint?.id
    };
    selectExactStep(0, trackingData);
  };

  // First send them to the select step, then open the business object modal.
  const goToSelectBusinessObject = () => {
    goToStepOne();
    showBusinessObjectSelectorModal();
  };

  const renderPleaseSelectBlueprint = () => {
    // this should actually never show up anymore since a user *must* select a default blueprint
    return (
      <SelectButtonContainer>
        <Button color="primary" onClick={() => {}} variant="contained">
          {text.selectBlueprint}
        </Button>
      </SelectButtonContainer>
    );
  };

  const renderPleaseSelectBusinessObject = () => {
    const creativeType =
      selectedBlueprint?.blueprint?.publishers[0]?.prototype?.code;

    if (
      creativeType === facebookCreativeTypes.dynamicAdCreative ||
      !isContentSelectable
    ) {
      return <span />;
    }

    return (
      <SelectButtonContainer>
        <Button
          color="primary"
          onClick={goToSelectBusinessObject}
          variant="contained"
        >
          {text.selectBusinessObject}
        </Button>
      </SelectButtonContainer>
    );
  };

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs,
    businessObjects: selectedBusinessObjects?.selectedBusinessObjects,
    selectedBusinessObjects,
    ...(isMultiLocation && { locationsOverrideById, selectedLocations })
  };

  const adPreviewProps = {
    architecture,
    contentName,
    previewData,
    customNoBlueprintMessage: renderPleaseSelectBlueprint(),
    customNoBusinessObjectMessage: renderPleaseSelectBusinessObject(),
    loading,
    error,
    facebookPageName: defaultFacebookPage?.name,
    isAutomated,
    blueprintsLoading,
    conditionalInputsVisibility,
    handleAdContentChannelValidation,
    setIsPollingPreview,
    isPollingPreview,
    setIsValidatingCreative,
    clearUpdatedInputCreativeErrors
  };

  const channels = selectedBlueprint?.blueprint?.channels;
  const programNameLabel = getProgramNameLabel(isMultiLocation);
  const sellingPoints = selectedBlueprint?.messaging?.sellingPoints || [];

  return (
    <AdPreviewDrawer
      previewDrawerOpen={previewDrawerOpen}
      togglePreviewDrawer={togglePreviewDrawer}
      toggleButtonText={
        currentStep === 0 ? text.adPreviewToggle : text.orderSummaryToggle
      }
    >
      <PreviewDrawerHeader
        isSummary={isSummary}
        channels={channels}
        isAutomation={isAutomated}
      />
      {isSummary && (
        <>
          <DrawerDivider />
          <SummaryItems
            channels={channels}
            defaultFacebookPage={defaultFacebookPage}
            isDrawerWidth={isDrawerWidth}
            selectedBusinessObjects={
              selectedBusinessObjects?.selectedBusinessObjects
            }
            displayNameTemplate={displayNameTemplate}
            contentName={contentName}
            programNameField={
              <EditableSummaryItem
                labelText={programNameLabel}
                isDrawerWidth={isDrawerWidth}
                uneditableContent={
                  programName !== undefined && programName !== ''
                    ? getProgramNameTranslated(
                        programName,
                        selectedBlueprint,
                        selectedBusinessObjects?.selectedBusinessObjects,
                        displayNameTemplate,
                        userMetadataFields
                      )
                    : ''
                }
              >
                <Box sx={{ width: '100%', mt: -4 }}>
                  <ProgramName
                    showHelpTextAsTooltip
                    contentColumns={contentColumns}
                    businessObjects={
                      selectedBusinessObjects?.selectedBusinessObjects
                    }
                    displayNameTemplate={displayNameTemplate}
                    selectedBlueprint={selectedBlueprint}
                    isAutomated={isAutomated}
                    containerSx={{ padding: 0 }}
                    inputVariant="standard"
                    ariaLabel={programNameLabel}
                    isContentSelectable={isContentSelectable}
                    contentName={contentName}
                  />
                </Box>
              </EditableSummaryItem>
            }
            automationNameField={
              isAutomated && (
                <>
                  <EditableSummaryItem
                    labelText={text.automationNameLabel}
                    isDrawerWidth={isDrawerWidth}
                    uneditableContent={
                      automatedProgramName !== undefined &&
                      automatedProgramName !== ''
                        ? getProgramNameTranslated(
                            automatedProgramName,
                            selectedBlueprint,
                            selectedBusinessObjects?.selectedBusinessObjects,
                            displayNameTemplate,
                            userMetadataFields
                          )
                        : ''
                    }
                  >
                    <Box sx={{ width: '100%', mt: -3 }}>
                      <AutomatedProgramName
                        contentName={contentName}
                        architecture={architecture}
                        isSummary
                        ariaLabel={text.automationNameLabel}
                      />
                    </Box>
                  </EditableSummaryItem>
                </>
              )
            }
          />
        </>
      )}
      <DrawerDivider />
      <Grid container spacing={!isSummary ? 0 : 2}>
        {isSummary && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex'
              }}
            >
              <SummaryLabel>{text.previewLabel}</SummaryLabel>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ maxWidth: 360, width: '100%' }}>
              {isMultiLocation ? (
                <BulkAdPreview
                  {...adPreviewProps}
                  showLoadingSkeleton
                  // Ensures that the ad previews don't shrink within the phone mock up frame on mobile
                  disableResponsiveStyles
                  // Display ad previews in phone mock up wrapper
                  displayAsPhoneMockUp
                  // Ad preview is fluidly responsive, the parent container sets the width
                  isResponsive
                  setIsValidatingCreative={setIsValidatingCreative}
                  selectedLocation={selectedLocation}
                  setCreativeValidationErrors={setCreativeValidationErrors}
                  showIncludedButton
                />
              ) : (
                <AdPreview
                  {...adPreviewProps}
                  showLoadingSkeleton
                  // Ensures that the ad previews don't shrink within the phone mock up frame on mobile
                  disableResponsiveStyles
                  // Display ad previews in phone mock up wrapper
                  displayAsPhoneMockUp
                  // Ad preview is fluidly responsive, the parent container sets the width
                  isResponsive
                  showIncludedButton
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {!!sellingPoints.length && (
        <>
          <DrawerDivider />
          <SellingPointsList sellingPoints={sellingPoints} />
        </>
      )}
      {(supportPhone || supportEmail) && (
        <>
          <DrawerDivider />
          <PreviewDrawerFooter
            supportPhone={supportPhone}
            supportEmail={supportEmail}
          />
        </>
      )}
    </AdPreviewDrawer>
  );
};

const mapStateToProps = state => {
  return {
    previewDrawerOpen: state?.program?.previewDrawerOpen
  };
};

export default flow(
  connect(mapStateToProps, {
    showBusinessObjectSelectorModal
  }),
  withRouter
)(ProgramPreviewDrawer);
